import * as React from "react";

import Disclaimer from "../components/Sections/Disclaimer";
import Layout from "../components/Base/Layout";
import { SvgBackground } from "../components/Base/SvgLogo";
import Ty from "../components/Register/TyForRegistration";

export default function TyForRegistration() {
  
  return (
    <Layout>
      <SvgBackground position="absolute" top="0" right="0"></SvgBackground>
      <Ty></Ty>
      <Disclaimer></Disclaimer>
    </Layout>
  );
}
