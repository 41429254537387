import { Box, Button, Container, HStack, Heading, Link, Text } from "@chakra-ui/react"

import { IconShieldCheck } from '@tabler/icons';
import React from "react"

const Ty: React.FC = () => {

    return (
    <Box bg="bg-surface" py="28" position="relative">
        <Container maxW="800px" px="4" py={{ base: '4', md: '8' }} >
            <Box display="flex" justifyContent="center" bg="white" py="14" px='2' textAlign="center" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);" >
                <Box w={{ base: '100%', md: "480px" }}>
                    <Heading size="xl" color="gray.800" > Thank you for registration! </Heading>
                    <Text lineHeight="7" py='4' color="gray.600">A registration email has been sent to the provided email. <br></br> Please check your Spam folder as well. </Text>
                    <Link href="/shop" _hover={{ textDecoration: "none" }} ><Button width="100%"> Start Shopping </Button></Link>
                    <HStack pt="20px" alignItems="center" justifyContent="center">
                        <IconShieldCheck fill="#8DCF30" stroke="#8DCF30" color="white"></IconShieldCheck>
                        <Text fontSize="sm" color="gray.500">We’ll notify you and answer any questions you may have.</Text>
                    </HStack>
                </Box>
            </Box>
        </Container>
    </Box>
    )
}

export default Ty